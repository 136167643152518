<template>
  <div id="searchList" class="xi">
    <nh-com></nh-com>
    <div class="content">
      <div class="search-box">
        <div class="search">
          <el-form :model="searchFrom">
            <el-select v-model="searchFrom.headSelected" placeholder="请选择">
              <el-option v-for="item in searchFrom.selectList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
            <el-input v-model="keyWord" placeholder="关键字查询..." style="width: 500px" @keyup.enter.native="SearchInputKeydown"></el-input>
            <button class="search-btn" type="button" @click="submitSearch()">搜索</button>
          </el-form>
        </div>
      </div>
      <div class="search-tabBox">
        <div class="search-tab">
          <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
            <el-tab-pane label="疾病" name="疾病" v-if="searchFrom.selectList.some((x) => x.name === '疾病')">
              <div :class="this.diseaseradio == 1 ? 'xi' : 'zhong'">
                <div class="content-left">
                  <div class="zhongxi">
                    <el-radio-group v-model="diseaseradio" @change="changeHandler">
                      <!--<el-radio label="0" border>全部</el-radio>-->
                      <el-radio label="2" border>中医</el-radio>
                      <el-radio label="1" border>西医</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="sidenav">
                    <h3 @click="searchDisease(0, false)" v-html="diseaseDepartmentList.length == 0 ? '无' : '全部'"></h3>
                    <el-menu class="el-menu-vertical-demo" :unique-opened="true">
                      <!--一级-->
                      <template>
                        <el-submenu :key="index" v-for="(sideNav, index) in diseaseDepartmentList" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.diseaseTypeName }}</span>
                          </template>
                          <!--二级-->
                          <div class="subtitle" style="border: 1px solid #f2f2f2">
                            <template v-for="subItem in sideNav.children">
                              <el-submenu v-if="subItem.children != null && Object.keys(subItem.children).length != 0" :index="subItem.diseaseTypeId + '2'">
                                <template slot="title">
                                  {{ subItem.diseaseTypeName }}
                                </template>
                                <!--三级-->

                                <el-menu-item v-for="(subItem2, i) in subItem.children" :key="i" :index="subItem2.diseaseTypeId + '4'" @click="searchDisease(subItem2.diseaseTypeId, false)">
                                  <template slot="title">
                                    {{ subItem2.diseaseTypeName }}
                                  </template>
                                </el-menu-item>
                              </el-submenu>
                              <el-menu-item v-else @click="searchDisease(subItem.diseaseTypeId, false)" :index="subItem.diseaseTypeId + '2'">
                                {{ subItem.diseaseTypeName }}
                              </el-menu-item>
                            </template>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="Diseasetotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ Diseasetotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="result-list">
                    <li class="resule-item clearfix" v-for="(item, i) in diseaseList" :key="i">
                      <div>
                        <div class="resule-left zy" :style="item.category.substring(0, 2) == '中医' ? 'background-color:#b76c12;' : 'background-color:#1fb7a6;'">
                          {{ item.category.substring(0, 4) }}
                        </div>
                        <div class="resule-info left">
                          <div class="clearfix">
                            <div class="cnname left" @click="toDiseaseDetail(item.diseaseKey)">
                              <h3>{{ item.diseaseName }}</h3>
                              <p>{{ item.diseaseAlias }}</p>
                            </div>
                            <div class="jiantou left" v-if="item.relation != null && item.relation.length != 0" @click="toDiseaseDetail(item.diseaseKey)">
                              <div class="jiantou-img">
                                <img height="30px" :src="item.category.substring(0, 2) == '中医' ? require('../assets/img/duizhao2.png') : require('../assets/img/duizhao.png')" alt="" />
                              </div>
                              <p style="display: none">中西医对应疾病</p>
                            </div>
                            <div class="enname left" v-if="item.relation != null && item.relation.length != 0" :style="item.category.substring(0, 2) == '中医' ? 'color:#1fb7a6;' : 'color:#b76c12;'">
                              <span v-for="relat in item.relation" @click="toDiseaseDetail(relat.relationKey)">{{ relat.relationName }}</span>
                            </div>
                          </div>
                          <div class="symptom">
                            <p v-if="item.mainSymptom">
                              主要症状：<span>{{ item.mainSymptom }}</span>
                            </p>
                          </div>
                          <div class="img-box clearfix" v-if="(item.category.indexOf('眼科') != -1 || item.category.indexOf('皮肤性病科') != -1) && item.image != null">
                            <div class="img-item left" v-for="imgItem in item.images">
                              <el-image class="swiImg" :src="imgItem" :preview-src-list="item.images"> </el-image>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="resule-star right">
                                                    <span class="iconfont">
                                                        <i class="el-icon-star-off"
                                                            @click="onCollection('disease', item)"></i>
                                                    </span>
                                                </div> -->
                      </div>
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="DiseasehandleCurrentChange" :current-page.sync="DiseasecurrentPage" :total="Diseasetotal"> </el-pagination>
                  </div>
                </div>
                <div class="nodata left" v-show="!Diseasetotal">
                  <img src="../../src/assets/img/no-data.png" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="症状" name="症状" v-if="searchFrom.selectList.some((x) => x.name === '症状')">
              <div :class="this.diseaseradio == 1 ? 'xi' : 'zhong'">
                <div class="content-left">
                  <div class="zhongxi">
                    <el-radio-group v-model="diseaseradio" @change="changeHandler">
                      <!--<el-radio label="0" border>全部</el-radio>-->
                      <el-radio label="2" border>中医</el-radio>
                      <el-radio label="1" border>西医</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="sidenav">
                    <h3 @click="searchDisease(0, false)" v-html="diseaseDepartmentList.length == 0 ? '无' : '全部'"></h3>
                    <el-menu class="el-menu-vertical-demo" unique-opened>
                      <!--一级-->
                      <template>
                        <el-submenu :key="index" v-for="(sideNav, index) in diseaseDepartmentList" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.diseaseTypeName }}</span>
                          </template>
                          <!--二级-->
                          <div class="subtitle" style="border: 1px solid #f2f2f2">
                            <template v-for="subItem in sideNav.children">
                              <el-submenu v-if="subItem.children != null && Object.keys(subItem.children).length != 0" :index="subItem.diseaseTypeId + '2'">
                                <template slot="title">
                                  {{ subItem.diseaseTypeName }}
                                </template>
                                <!--三级-->

                                <el-menu-item v-for="(subItem2, i) in subItem.children" :key="i" :index="subItem2.diseaseTypeId + '4'" @click="searchDisease(subItem2.diseaseTypeId, false)">
                                  <template slot="title">
                                    {{ subItem2.diseaseTypeName }}
                                  </template>
                                </el-menu-item>
                              </el-submenu>
                              <el-menu-item v-else @click="searchDisease(subItem.diseaseTypeId, false)" :index="subItem.diseaseTypeId + '2'">
                                {{ subItem.diseaseTypeName }}
                              </el-menu-item>
                            </template>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="Diseasetotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ Diseasetotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="result-list">
                    <li class="resule-item clearfix" v-for="(item, i) in diseaseList" :key="i">
                      <div>
                        <div class="resule-left zy" :style="item.category.substring(0, 2) == '中医' ? 'background-color:#b76c12;' : 'background-color:#1fb7a6;'">
                          {{ item.category.substring(0, 4) }}
                        </div>
                        <div class="resule-info left">
                          <div class="clearfix">
                            <div class="cnname left" @click="toDiseaseDetail(item.diseaseKey)">
                              <h3>{{ item.diseaseName }}</h3>
                              <p>{{ item.diseaseAlias }}</p>
                            </div>
                            <div class="jiantou left" v-if="item.relation != null && item.relation.length != 0" @click="toDiseaseDetail(item.diseaseKey)">
                              <div class="jiantou-img">
                                <img height="30px" :src="item.category.substring(0, 2) == '中医' ? require('../assets/img/duizhao2.png') : require('../assets/img/duizhao.png')" alt="" />
                              </div>
                              <p style="display: none">中西医对应疾病</p>
                            </div>
                            <div class="enname left" v-if="item.relation != null && item.relation.length != 0" :style="item.category.substring(0, 2) == '中医' ? 'color:#1fb7a6;' : 'color:#b76c12;'">
                              <span v-for="relat in item.relation" @click="toDiseaseDetail(relat.relationKey)">{{ relat.relationName }}</span>
                            </div>
                          </div>
                          <div class="symptom">
                            <p v-if="item.mainSymptom">
                              主要症状：<span>{{ item.mainSymptom }}</span>
                            </p>
                          </div>
                          <div class="img-box clearfix" v-if="(item.category.indexOf('眼科') != -1 || item.category.indexOf('皮肤性病科') != -1) && item.image != null">
                            <div class="img-item left" v-for="imgItem in item.images">
                              <el-image class="swiImg" :src="imgItem" :preview-src-list="item.images"> </el-image>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="resule-star right">
                                                    <span class="iconfont">
                                                        <i class="el-icon-star-off"
                                                            @click="onCollection('disease', item)"></i>
                                                    </span>
                                                </div> -->
                      </div>
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="DiseasehandleCurrentChange" :current-page.sync="DiseasecurrentPage" :total="Diseasetotal"> </el-pagination>
                  </div>
                </div>
                <div class="nodata left" v-show="!Diseasetotal">
                  <img src="../../src/assets/img/no-data.png" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="药品" name="药品" v-if="searchFrom.selectList.some((x) => x.name === '药品')">
              <div :class="this.drugradio == 1 ? 'xi' : 'zhong'">
                <div class="content-box clearfix">
                  <div class="content-left">
                    <div class="zhongxi">
                      <el-radio-group v-model="drugradio" @change="drugchangeHandler">
                        <!--<el-radio label="0" border>全部</el-radio>-->
                        <el-radio label="2" border>中成药</el-radio>
                        <el-radio label="1" border>西药</el-radio>
                      </el-radio-group>
                    </div>
                    <div class="sidenav">
                      <h3 @click="searchDrug(0)">全部药品</h3>
                      <el-menu class="el-menu-vertical-demo" unique-opened>
                        <template>
                          <el-submenu v-for="(sideNav, index) in drugCategoryList" :key="index" :index="index + ''">
                            <template slot="title">
                              <span>{{ sideNav.categoryName }}</span>
                            </template>
                            <div class="subtitle" style="border: 1px solid #f2f2f2">
                              <template v-for="subItem in sideNav.children">
                                <template v-if="subItem.children != null && subItem.children.length > 0">
                                  <el-submenu :index="subItem.categoryId + ''">
                                    <template slot="title">
                                      {{ subItem.categoryName }}
                                    </template>

                                    <el-menu-item @click="searchDrug(subItem.categoryId)" :index="subItem.categoryId + '2'">
                                      <template slot="title"> 全部 </template>
                                    </el-menu-item>
                                    <el-menu-item v-for="(subItem2, i) in subItem.children" :key="i" :index="subItem2.categoryId + ''" @click="searchDrug(subItem2.categoryId)">
                                      <template slot="title">
                                        {{ subItem2.categoryName }}
                                      </template>
                                    </el-menu-item>
                                  </el-submenu>
                                </template>
                                <template v-else>
                                  <el-menu-item @click="searchDrug(subItem.categoryId)" :index="subItem.categoryId + ''" style="padding-left: 30px !important">
                                    <template slot="title">
                                      {{ subItem.categoryName }}
                                    </template>
                                  </el-menu-item>
                                </template>
                              </template>
                            </div>
                          </el-submenu>
                        </template>
                      </el-menu>
                    </div>
                  </div>
                  <div class="content-right" v-show="drugtotal">
                    <div class="result-tit">
                      <p>
                        为您找到 <span>{{ drugtotal }}</span
                        ><em>个结果</em>
                      </p>
                    </div>
                    <ul class="result-list drug">
                      <li v-for="(drugItem, i) in DrugList" :key="i" class="resule-item clearfix">
                        <div class="resule-left xy" :style="drugItem.category == '中成药' ? 'background-color:#b76c12;' : 'background-color:#1f6eb7;'">
                          {{ drugItem.category == "中成药" ? "中" : "西" }}
                        </div>
                        <div class="resule-info left" @click="toDrugDetail(drugItem.hashKey)">
                          <div class="clearfix">
                            <div class="drugName left">
                              <h3>
                                {{ drugItem.drugName }}
                                <span v-if="drugItem.drugTradeName != null && drugItem.drugTradeName != ''"> ({{ drugItem.drugTradeName }}) </span>
                              </h3>
                            </div>
                          </div>
                          <div class="indication">
                            <p>适应症：<span v-html="drugItem.indication"></span></p>
                          </div>
                        </div>
                        <!-- <div class="resule-star right">
                                                    <span class="iconfont">
                                                        <i class="el-icon-star-off"
                                                            @click="onCollection('drug', drugItem)"></i>
                                                    </span>
                                                </div> -->
                      </li>
                    </ul>
                    <div class="page">
                      <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="DrughandleCurrentChange" :current-page.sync="drugcurrentPage" :total="drugtotal"> </el-pagination>
                    </div>
                  </div>
                  <div class="nodata left" v-show="!drugtotal">
                    <img src="../../src/assets/img/no-data.png" alt="" />
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="中药方剂" name="中药方剂" v-if="searchFrom.selectList.some((x) => x.name === '中药方剂')">
              <div class="zhong">
                <div class="content-left">
                  <div class="sidenav">
                    <h3 @click="searchherbal(0)">全部方剂</h3>
                    <el-menu class="el-menu-vertical-demo" unique-opened>
                      <template>
                        <el-submenu v-for="(sideNav, index) in herbalNavList" :key="index" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.categoryName }}</span>
                          </template>
                          <div class="subtitle">
                            <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i" :index="subItem.categoryId + ''" @click="searchherbal(subItem.categoryId)">
                              <template slot="title">
                                {{ subItem.categoryName }}
                              </template>
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="herbaltotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ herbaltotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="food-list">
                    <li class="food-item" v-for="(herbalDetailItem, i) in herbalDetailList">
                      <h3 class="food-tit" @click="toherbalDetail(herbalDetailItem.hashKey)">
                        {{ herbalDetailItem.herbalPrescriptionName }}
                        <span v-if="categoryherbal(herbalDetailItem)">{{ categoryherbal(herbalDetailItem) }}</span>
                      </h3>
                      <p v-if="herbalDetailItem.Source">
                        出自： <em>{{ herbalDetailItem.source }}</em>
                      </p>
                      <!-- <div class="sc-btn">
                                                <i class="el-icon-star-off"
                                                    @click="onCollection('herbal', herbalDetailItem)"></i>
                                            </div> -->
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="herbalhandleCurrentChange" :current-page.sync="herbalcurrentPage" :total="herbaltotal"> </el-pagination>
                  </div>
                </div>
                <div class="nodata left" v-show="!herbaltotal">
                  <img src="../../src/assets/img/no-data.png" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="中药饮片" name="中药饮片" v-if="searchFrom.selectList.some((x) => x.name === '中药饮片')">
              <div class="content-box zhong">
                <div class="content-left">
                  <div class="sidenav">
                    <h3 @click="searchMaterial(0)">全部中药</h3>
                    <el-menu unique-opened class="el-menu-vertical-demo">
                      <template>
                        <el-submenu v-for="(sideNav, index) in materialNavList" :key="index" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.categoryName }}</span>
                          </template>
                          <div class="subtitle">
                            <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i" :index="subItem.categoryId + ''" @click="searchMaterial(subItem.categoryId)">
                              <template slot="title">
                                {{ subItem.categoryName }}
                              </template>
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="materialtotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ materialtotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="material-list clearfix">
                    <li class="material-item" v-for="(materialDetailItem, i) in materialDetailList" :key="i">
                      <div class="material-img">
                        <div v-if="!materialDetailItem.materialPic" class="img-box" @click="toMaterialDetail(materialDetailItem.hashKey)">
                          <img src="../assets/img/zyc_img.png" alt="" />
                        </div>
                        <div v-else class="img-box" style="position: relative">
                          <img v-lazy="materialDetailItem.materialPic" alt="" />
                        </div>
                      </div>
                      <div class="material-tit clearfix">
                        <div class="material-name left">
                          <h3 @click="toMaterialDetail(materialDetailItem.hashKey)">
                            <i>{{ materialDetailItem.materialName }}</i
                            ><span>{{ Materialcategory(materialDetailItem) }}</span>
                          </h3>
                        </div>
                      </div>
                      <!-- <div class="result-hover">
                                                <div class="add-pw left">+加入配伍</div>
                                                <div class="collect-btn right">
                                                    <i class="el-icon-star-off"
                                                        @click="onCollection('material', materialDetailItem)"></i>
                                                </div>
                                            </div> -->
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="MaterialhandleCurrentChange" :current-page.sync="materialcurrentPage" :total="materialtotal"> </el-pagination>
                  </div>
                </div>
              </div>
              <div class="nodata left" v-show="!materialtotal">
                <img src="../../src/assets/img/no-data.png" alt="" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="食疗药膳" name="食疗药膳" v-if="searchFrom.selectList.some((x) => x.name === '食疗药膳')">
              <div class="zhong">
                <div class="content-left">
                  <div class="sidenav">
                    <h3 @click="searchFood(0)">全部药膳</h3>
                    <el-menu unique-opened class="el-menu-vertical-demo">
                      <template>
                        <el-submenu v-for="(sideNav, index) in foodNavList" :key="index" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.categoryName }}</span>
                          </template>
                          <div class="subtitle">
                            <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i" :index="subItem.categoryId + ''" @click="searchFood(subItem.categoryId)">
                              <template slot="title">
                                {{ subItem.categoryName }}
                              </template>
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="foodtotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ foodtotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="food-list">
                    <li class="food-item" v-for="(foodDetailItem, i) in foodDetailList" @click="toFoodDetail(foodDetailItem.hashKey)">
                      <h3 class="food-tit">
                        {{ foodDetailItem.foodMaterialPrescriptionName }} <span v-if="category(foodDetailItem)">{{ category(foodDetailItem) }}</span>
                      </h3>
                      <p v-if="foodDetailItem.source">
                        出自： <em>{{ foodDetailItem.source }}</em>
                      </p>
                      <!-- <div class="sc-btn">
                                                <i class="el-icon-star-off"
                                                    @click="onCollection('food', foodDetailItem)"></i>
                                            </div> -->
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="foodhandleCurrentChange" :current-page.sync="foodcurrentPage" :total="foodtotal"> </el-pagination>
                  </div>
                </div>
              </div>
              <div class="nodata left" v-show="!foodtotal">
                <img src="../../src/assets/img/no-data.png" alt="" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="经络穴位" name="经络穴位" v-if="searchFrom.selectList.some((x) => x.name === '经络穴位')">
              <div class="zhong">
                <div class="content-left">
                  <div class="sidenav">
                    <h3 @click="searchMeridian(0)">全部经络</h3>
                    <el-menu class="el-menu-vertical-demo" unique-opened>
                      <template>
                        <el-submenu v-for="(sideNav, index) in meridianNavList" :key="index" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.categoryName }}</span>
                          </template>
                          <div class="subtitle">
                            <el-menu-item v-for="(subItem, i) in sideNav.childrens" :key="i" :index="subItem.categoryId + ''" @click="searchMeridian(subItem.categoryId)">
                              <template slot="title">
                                {{ subItem.categoryName }}
                              </template>
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="meridiantotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ meridiantotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="food-list">
                    <li class="food-item" v-for="(meridianDetailItem, i) in meridianDetailList">
                      <div>
                        <h3 class="meridian-tit" @click="toMeridianDetail(meridianDetailItem.hashKey)">
                          {{ meridianDetailItem.meridianName }}
                        </h3>
                        <p>
                          包含穴位： <el-button plain size="mini" style="margin: 5px" type="warning" v-for="(meridianTag, i) in meridianDetailItem.acupoints" :key="i" @click="toAcupointDetail(meridianTag.hashKey)" v-html="meridianTag.acupointName">{{ meridianTag.acupointName }} </el-button>
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="handleCurrentChange" :current-page.sync="meridiancurrentPage" :total="meridiantotal"> </el-pagination>
                  </div>
                </div>
              </div>
              <div class="nodata left" v-show="!meridiantotal">
                <img src="../../src/assets/img/no-data.png" alt="" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="健康饮食" name="健康饮食" v-if="searchFrom.selectList.some((x) => x.name === '健康饮食')">
              <div class="zhong">
                <div class="content-left">
                  <div class="sidenav">
                    <h3 @click="searchHealthyDiet(0)">全部健康饮食</h3>
                    <el-menu unique-opened class="el-menu-vertical-demo">
                      <template>
                        <el-submenu v-for="(sideNav, index) in healthyNavList" :key="index" :index="index + ''">
                          <template slot="title">
                            <span>{{ sideNav.categoryName }}</span>
                          </template>
                          <div class="subtitle">
                            <el-menu-item @click="searchHealthyDiet(sideNav.categoryId)">
                              <template slot="title"> 全部 </template>
                            </el-menu-item>
                            <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i" :index="subItem.categoryId + ''" @click="searchHealthyDiet(subItem.categoryId)">
                              <template slot="title">
                                {{ subItem.categoryName }}
                              </template>
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </template>
                    </el-menu>
                  </div>
                </div>
                <div class="content-right" v-show="healthytotal">
                  <div class="result-tit">
                    <p>
                      为您找到 <span>{{ healthytotal }}</span
                      ><em>个结果</em>
                    </p>
                  </div>
                  <ul class="food-list">
                    <li class="food-item" v-for="(foodDetailItem, i) in healthyDetailList" @click="toHealthyDiet(foodDetailItem.hashKey)">
                      <h3 class="food-tit">
                        {{ foodDetailItem.name }} <span v-if="category(foodDetailItem)">{{ category(foodDetailItem) }}</span>
                      </h3>
                      <p v-if="foodDetailItem.source">
                        出自： <em>{{ foodDetailItem.source }}</em>
                      </p>
                      <!-- <div class="sc-btn">
                                                <i class="el-icon-star-off"
                                                    @click="onCollection('food', foodDetailItem)"></i>
                                            </div> -->
                    </li>
                  </ul>
                  <div class="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="healthyhandleCurrentChange" :current-page.sync="healthycurrentPage" :total="healthytotal"> </el-pagination>
                  </div>
                </div>
              </div>
              <div class="nodata left" v-show="!healthytotal">
                <img src="../../src/assets/img/no-data.png" alt="" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../components/footer";
import nhCom from "../components/header";
import { HealthyDiet } from "../components/Drug/HealthyDiet";
import { Disease } from "../components/Disease/Disease";
import { Drug } from "../components/Drug/Drug";
import { Material } from "../components/Drug/Material";
import { Herbal } from "../components/Drug/Herbal";
import { Food } from "../components/Drug/Food";
import { Meridian } from "../components/Disease/Meridian";
import { toAcupoint, toMeridian, toHealthyDetail } from "../utils/relation";
import { Menus } from "../components/Menu/Menu";
import { Collection } from "../components/collection/Collection";
var searchId = 0;
var pageIndex = 1;
var categoryId = 0;
export default {
  name: "searchList",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    var healthyDiet = new HealthyDiet(this.TokenClient, this.Services.Drug);
    var disease = new Disease(this.TokenClient, this.Services.Disease);
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    var material = new Material(this.TokenClient, this.Services.Drug);
    var herbal = new Herbal(this.TokenClient, this.Services.Drug);
    var food = new Food(this.TokenClient, this.Services.Drug);
    var meridian = new Meridian(this.TokenClient, this.Services.Disease);
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    var collection = new Collection(this.TokenClient, this.Services.Statics);
    var actiname = this.$route.query.activeName;
    if (this.$route.query.activeName == "症状") actiname = "疾病";
    var searchOptions = menu.GetSearchModulesNew();
    return {
      collectionDomain: collection,
      activeName: actiname,
      searchFrom: {
        selectList: searchOptions,
        headSelected: this.$route.query.activeName,
      },
      keyWord: this.$route.query.keyWord, //列表页KeyWord
      isCollection: false,
      //疾病
      diseaseradio: "2",
      diseaseDepartmentList: [],
      diseaseList: [],
      Diseasetotal: 0,
      DiseasecurrentPage: 1, //初始页
      DiscategoryName: "中医",
      //药品
      drugradio: "2",
      drugcategoryName: "中成药",
      drugCategoryList: [],
      DrugList: [],
      drugtotal: 0,
      drugcurrentPage: 1, //初始页
      //食疗药膳
      foodNavList: [],
      foodDetailList: [],
      foodtotal: 0,
      foodcurrentPage: 1, //初始页
      //中药方剂
      herbalNavList: [],
      herbalDetailList: [],
      herbaltotal: 0,
      herbalcurrentPage: 1, //初始页
      //健康饮食
      healthyNavList: [],
      healthyDetailList: [],
      healthytotal: 0,
      healthycurrentPage: 1, //初始页
      //中药
      materialNavList: [],
      materialDetailList: [],
      materialtotal: 0,
      materialcurrentPage: 1, //初始页
      //经络穴位
      meridianNavList: [],
      meridianDetailList: [],
      meridiantotal: 0,
      meridiancurrentPage: 1, //初始页
      rloading: [], //loading遮罩
      diseaseDomain: disease,
      drugDomain: drug,
      materialDomain: material,
      herbalDomain: herbal,
      foodDomain: food,
      meridianDomain: meridian,
      herbalnavDomain: healthyDiet,
      isClinical: this.$route.query.activeName == "症状" ? 1 : 0, //是否为症状查询
      currentlyAvailable:false
    };
  },
  activated() {
    this.keyWord = this.$route.query.keyWord;
    this.loadData();
  },
  mounted() {
    this.currentlyAvailable = this.searchFrom.selectList[0].currentlyAvailable;
    // this.loadData()
  },
  methods: {
    loadData() {
      if (this.activeName == "疾病" || this.activeName == "症状") {
        if (this.activeName == "症状") this.isClinical = 1;
        this.searchDisease(categoryId, pageIndex);
        this.getDiseaseDepartmentList();
      } else if (this.activeName == "药品") {
        this.searchDrug(categoryId, pageIndex);
        this.initCategroy(this.drugcategoryName);
      } else if (this.activeName == "中药材") {
        this.searchMaterial(categoryId, pageIndex);
        this.getmaterialNavListData();
      } else if (this.activeName == "食疗药膳") {
        this.searchFood(categoryId, pageIndex);
        this.getfoodNavListData();
      } else if (this.activeName == "中药方剂") {
        this.searchherbal(categoryId, pageIndex);
        this.herbalCategories();
      } else if (this.activeName == "经络穴位") {
        this.searchMeridian(categoryId, pageIndex);
        this.getMeridianNavListData();
      } else if (this.activeName == "健康饮食") {
        this.searchHealthyDiet(categoryId, pageIndex);
        this.getHealthyDietnNavListData();
      }
    },
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    submitSearch: function () {
      if (this.keyWord == "") {
        // this.$alert('搜索内容不能为空', '提示', {
        //     confirmButtonText: '确定',
        // })
        this.$message({
          type: "warning",
          message: "搜索内容不能为空",
        });
      } else {
        var headSelected = this.searchFrom.headSelected;
        if (this.searchFrom.headSelected === "all") {
          headSelected = this.searchFrom.selectList[1].id;
        }
        switch (headSelected) {
          case "disease":
            this.activeName = "疾病";
            this.isClinical = 0;
            this.searchDisease(categoryId);
            break;
          case "疾病":
            this.activeName = "疾病";
            this.isClinical = 0;
            this.searchDisease(categoryId);
            break;
          case "zhengzhuang":
            this.activeName = "症状";
            this.isClinical = 1;
            this.searchDisease(categoryId);
            break;
          case "症状":
            this.activeName = "症状";
            this.isClinical = 1;
            this.searchDisease(categoryId);
            break;
          case "drug":
            this.activeName = "药品";
            this.searchDrug(categoryId);
            this.initCategroy(this.drugcategoryName);
            break;
          case "药品":
            this.activeName = "药品";
            this.searchDrug(categoryId);
            this.initCategroy(this.drugcategoryName);
            break;
          case "material":
            this.activeName = "中药饮片";
            this.searchMaterial(categoryId);
            this.getmaterialNavListData();
            break;
          case "中药饮片":
            this.activeName = "中药饮片";
            this.searchMaterial(categoryId);
            this.getmaterialNavListData();
            break;
          case "food":
            this.activeName = "食疗药膳";
            this.searchFood(categoryId);
            this.getfoodNavListData();
            break;
          case "食疗药膳":
            this.activeName = "食疗药膳";
            this.searchFood(categoryId);
            this.getfoodNavListData();
            break;
          case "herbal":
            this.activeName = "中药方剂";
            this.searchherbal(categoryId);
            this.herbalCategories();
            break;
          case "中药方剂":
            this.activeName = "中药方剂";
            this.searchherbal(categoryId);
            this.herbalCategories();
            break;
          case "meridian":
            this.activeName = "经络穴位";
            this.searchMeridian(categoryId);
            this.getMeridianNavListData();
            break;
          case "经络穴位":
            this.activeName = "经络穴位";
            this.searchMeridian(categoryId);
            this.getMeridianNavListData();
            break;
          case "healthyDiet":
            this.activeName = "健康饮食";
            this.searchMeridian(categoryId);
            this.getHealthyDietnNavListData();
            break;
          case "健康饮食":
            this.activeName = "健康饮食";
            this.searchHealthyDiet(categoryId);
            this.getHealthyDietnNavListData();
            break;
        }
      }
    },
    SearchInputKeydown(event) {
      this.submitSearch();
    },
    handleClick(tab) {
      this.currentlyAvailable = this.searchFrom.selectList[tab.index].currentlyAvailable;
      this.searchFrom.headSelected = tab.name;
      switch (tab.name) {
        case "疾病":
          this.isClinical = 0;
          this.searchDisease(0);
          this.getDiseaseDepartmentList();
          break;
        case "症状":
          this.isClinical = 1;
          this.searchDisease(0);
          this.getDiseaseDepartmentList();
          break;
        case "药品":
          this.searchDrug(0);
          this.initCategroy(this.drugcategoryName);
          break;
        case "中药饮片":
          this.searchMaterial(0);
          this.getmaterialNavListData();
          break;
        case "食疗药膳":
          this.searchFood(0);
          this.getfoodNavListData();
          break;
        case "中药方剂":
          this.searchherbal(0);
          this.herbalCategories();
          break;
        case "经络穴位":
          this.searchMeridian(0);
          this.getMeridianNavListData();
          break;
        case "健康饮食":
          this.searchHealthyDiet(0);
          this.getHealthyDietnNavListData();
          break;
      }
    },
    /*-------------------------------------------------疾病-------------------------------------------------*/
    //radio change事件
    changeHandler: function (value) {
      this.diseaseradio = value;
      this.searchDisease(0);
      this.getDiseaseDepartmentList();
    },
    //右侧药品列表获取
    searchDisease: function (department, isPage = false) {
      categoryId = department;
      // console.log(categoryId)
      var _this = this;
      this.rloading = this.openLoading();
      if (!isPage) {
        pageIndex = 1;
        this.DiseasecurrentPage = 1;
      }
      {
        var category = "全部";
        if (this.diseaseradio == 1) category = "西医科室";
        if (this.diseaseradio == 2) category = "中医科室";
      }
      if (!this.isClinical) {
        this.isClinical = 0;
      }
      this.diseaseDomain.Clinicals(
        this.isClinical,
        category,
        pageIndex,
        categoryId,
        this.keyWord,
        function (res) {
          searchId = res.msg;
          _this.diseaseList = res.data.results;
          _this.Diseasetotal = res.data.dataTotal;
        },
        function (error) {
          console.log("疾病列表请求失败!请刷新重试:", error);
        }
      );
      // this.department = department;
      this.rloading.close();
      if (!isPage) this.currentPage = 1;
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    getDiseaseDepartmentList() {
      var category = "全部";
      if (this.diseaseradio == 1) category = "西医科室";
      if (this.diseaseradio == 2) category = "中医科室";
      this.diseaseDomain.DiseaseDepartmentList(
        category,
        (res) => {
          this.diseaseDepartmentList = res.data;
          this.diseaseDepartmentList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                diseaseTypeId: item.diseaseTypeId,
                diseaseTypeName: "全部",
              });
            } else {
              item.children = [
                {
                  diseaseTypeId: item.diseaseTypeId,
                  diseaseTypeName: "全部",
                },
              ];
            }
          });
        },
        (error) => {
          console.log("疾病列表请求失败!请刷新重试:", error);
        }
      );
    },
    //分页点击事件
    DiseasehandleCurrentChange: function (pageNum) {
      this.DiseasecurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchDisease(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    toDiseaseDetail(hashKey) {
      ///api/Drug/{drugId}/{searchId}/{searchType}/{keyWord}
      if (!this.currentlyAvailable) {
        this.openTip();
        return;
      }
      this.$router.push({
        path: "/disease/DiseaseDetail",
        query: {
          hashKey: hashKey,
        },
      });
    },
    onCollection: function (type, item) {
      var _this = this;
      var entityType = 0;
      if (type == "disease" || type == "drug") {
        if (item.category.indexOf("西医") != -1) {
          entityType = 1;
        }
        if (item.category.indexOf("中医") != -1) {
          entityType = 2;
        }
        if (item.category.indexOf("化学药品和生物制品") != -1) {
          entityType = 3;
        }
        if (item.category.indexOf("中成药") != -1) {
          entityType = 4;
        }
      }
      switch (type) {
        case "disease":
          _this.collectionDomain.Put(
            "disease",
            item.diseaseName,
            item.diseaseKey,
            item.diseaseId,
            entityType,
            function (data) {
              _this.$message({
                type: "success",
                message: "收藏成功!",
              });
            },
            function (err) {
              if (err.status === 400) {
                _this.$message.error("请勿重复收藏");
              }
            }
          );
          break;
        case "herbal":
          _this.collectionDomain.Put(
            "herbal",
            item.herbalPrescriptionName,
            item.hashKey,
            item.herbalPrescriptionId,
            0,
            function (data) {
              _this.$message({
                type: "success",
                message: "收藏成功!",
              });
            },
            function (err) {
              if (err.status === 400) {
                _this.$message.error("请勿重复收藏");
              }
            }
          );
          break;
        case "drug":
          _this.collectionDomain.Put(
            "drug",
            item.drugName,
            item.hashKey,
            item.drugId,
            entityType,
            function (data) {
              _this.$message({
                type: "success",
                message: "收藏成功!",
              });
            },
            function (err) {
              if (err.status === 400) {
                _this.$message.error("请勿重复收藏");
              }
            }
          );
          break;
        case "food":
          _this.collectionDomain.Put(
            "food",
            item.foodMaterialPrescriptionName,
            item.hashKey,
            item.foodMaterialPrescriptionId,
            0,
            function (data) {
              _this.$message({
                type: "success",
                message: "收藏成功!",
              });
            },
            function (err) {
              if (err.status === 400) {
                _this.$message.error("请勿重复收藏!");
              }
            }
          );
          break;
        case "material":
          _this.collectionDomain.Put(
            "material",
            item.materialName,
            item.hashKey,
            item.materialId,
            0,
            function (data) {
              _this.$message({
                type: "success",
                message: "收藏成功!",
              });
            },
            function (err) {
              if (err.status === 400) {
                _this.$message.error("请勿重复收藏");
              }
            }
          );
          break;
      }
    },
    /*-------------------------------------------------药品-------------------------------------------------*/
    //radio change事件
    drugchangeHandler: function (value) {
      if (value == 2) this.drugcategoryName = "中成药";
      if (value == 1 || value == 0) this.drugcategoryName = "化学药品和生物制品";
      this.drugradio = value;
      this.initCategroy(this.drugcategoryName);
      this.searchDrug(0);
    },
    //初始化药品分类
    initCategroy: function (drugcategoryName) {
      var _this = this;
      _this.drugDomain.DrugCategories(
        drugcategoryName,
        function (data) {
          _this.drugCategoryList = data.data;
          _this.drugCategoryList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.children = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("药品分类请求失败!请刷新重试。", error);
        }
      );
    },
    //右侧药品列表获取
    searchDrug: function (cateId, isPage = false) {
      var _this = this;
      this.rloading = this.openLoading();
      if (!isPage) this.drugcurrentPage = 1;
      var category = "全部";
      if (this.drugradio == 1) category = "化学药品和生物制品";
      if (this.drugradio == 2) category = "中成药";
      ///api/Drug/Search/{category}/{searchId}/{pageIndex}/{categoryId}/{keyWord}
      let params = `${category}/${pageIndex}/${cateId}`;
      if (this.keyWord != "") {
        params = params + `/${this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.drugDomain.Drugs(
        params,
        function (data) {
          _this.DrugList = data.data.results;
          _this.drugtotal = data.data.dataTotal;
          categoryId = cateId;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("药品列表请求失败!请刷新重试。", error);
        }
      );
      if (!isPage) {
        this.drugcurrentPage = 1;
      }
      this.searchHeper("药品");
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //分页点击事件
    DrughandleCurrentChange: function (pageNum) {
      this.drugcurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchDrug(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    openTip() {
      this.$confirm("您当前版本不支持此功能，请前往升级更高版本解锁此功能！", "提示", {
        confirmButtonText: "去升级",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/personal/upgrade");
        })
        .catch(() => {});
    },
    //跳转到详情
    toDrugDetail(hashKey) {
      ///api/Drug/{drugId}/{searchId}/{searchType}/{keyWord}
      if (!this.currentlyAvailable) {
        this.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/DrugDetail",
        query: {
          hashKey: hashKey,
        },
      });
    },
    /*-------------------------------------------------中药-------------------------------------------------*/
    //左侧分类获取
    getmaterialNavListData: function () {
      var _this = this;
      _this.materialDomain.Categorys(
        function (data) {
          _this.materialNavList = data.data;
          _this.materialNavList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.children = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("中药分类请求失败:", error);
        }
      );
    },
    searchMaterial(cateId, isPage = false) {
      var _this = this;
      this.rloading = this.openLoading();
      if (!isPage) pageIndex = 1;
      let params = `/${cateId}/${pageIndex}`;
      if (this.keyWord != "") {
        params = params + `/${this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.materialDomain.Materials(
        params,
        function (data) {
          _this.materialDetailList = data.data.results;
          _this.materialtotal = data.data.dataTotal;
          categoryId = cateId;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("中药材请求失败:", error);
        }
      );
      if (!isPage) {
        this.materialcurrentPage = 1;
      }
      this.searchHeper("中药");
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //右侧中药属性赋值
    Materialcategory: function (data) {
      var categoryName = "";
      if (data.category != null) {
        if (data.category.Parent != null) {
          categoryName = data.category.Parent.categoryName + "/";
        }
        categoryName += data.category.categoryName;
      }
      return categoryName;
    },
    //分页点击事件
    MaterialhandleCurrentChange: function (pageNum) {
      this.materialcurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchMaterial(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    toMaterialDetail(hashKey) {
      ///api/Material/{id}/{searchId}/{keyWord}
      if (!this.currentlyAvailable) {
        this.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/MaterialDetail",
        //name:'materialDetail',
        query: {
          hashKey: hashKey,
        },
      });
    },
    /*-------------------------------------------------中药方剂-------------------------------------------------*/
    //左侧分类获取
    herbalCategories: function () {
      var _this = this;
      _this.herbalDomain.Categorys(
        function (data) {
          _this.herbalNavList = data.data;
          _this.herbalNavList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.children = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    searchherbal(cateId, isPage = false) {
      var _this = this;
      this.rloading = this.openLoading();
      if (!isPage) pageIndex = 1;
      let params = `/${cateId}/${pageIndex}`;
      if (this.keyWord != "") {
        params = params + `/${this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.herbalDomain.Herbals(
        params,
        function (data) {
          _this.herbalDetailList = data.data.results;
          _this.herbaltotal = data.data.dataTotal;
          categoryId = cateId;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("中药方剂请求失败:", error);
        }
      );
      if (!isPage) {
        this.herbalcurrentPage = 1;
      }
      this.searchHeper("中药方剂");
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //右侧中药方剂属性获取
    categoryherbal: function (data) {
      var categoryName = "";
      if (data.herbalPrescriptionCategory != null) {
        if (data.herbalPrescriptionCategory.parent != null) {
          categoryName = data.herbalPrescriptionCategory.parent.categoryName + "/";
        }
        categoryName += data.herbalPrescriptionCategory.categoryName;
      }
      return categoryName;
    },
    //分页点击事件
    herbalhandleCurrentChange: function (pageNum) {
      this.herbalcurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchherbal(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    toherbalDetail(hashKey) {
        if (!this.currentlyAvailable) {
        this.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/herbalDetail",
        //name:'herbalDetail',
        query: {
          hashKey: hashKey,
        },
      });
    },
    /*-------------------------------------------------食疗药膳-------------------------------------------------*/
    //左侧分类获取
    getfoodNavListData: function () {
      var _this = this;
      _this.foodDomain.Categorys(
        function (data) {
          _this.foodNavList = data.data;
          _this.foodNavList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.children = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("食疗药膳分类请求失败:", error);
        }
      );
    },
    //右侧食疗药膳列表获取
    searchFood: function (cateId, isPage = false) {
      var _this = this;
      this.rloading = this.openLoading();
      if (!isPage) pageIndex = 1;
      let params = `/${cateId}/${pageIndex}`;
      if (this.keyWord != "") {
        params = params + `/${this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.foodDomain.Foods(
        params,
        function (data) {
          _this.foodDetailList = data.data.results;
          _this.foodtotal = data.data.dataTotal;
          categoryId = cateId;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("食疗药膳列表请求失败:", error);
        }
      );
      if (!isPage) {
        this.foodcurrentPage = 1;
      }
      this.searchHeper("食疗药膳");
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //右侧食疗药膳属性获取
    category: function (data) {
      var categoryName = "";
      if (data.foodMaterialPrescriptionCategory != null) {
        if (data.foodMaterialPrescriptionCategory.Parent != null) {
          categoryName = data.foodMaterialPrescriptionCategory.parent.categoryName + "/";
        }
        categoryName += data.foodMaterialPrescriptionCategory.categoryName;
      }
      return categoryName;
    },
    //分页点击事件
    foodhandleCurrentChange: function (pageNum) {
      this.foodcurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchFood(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    toFoodDetail(hashKey) {
      ///api/FoodPrescription/{hashKey}
      if (!this.currentlyAvailable) {
        this.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/FoodDetail",
        //name:'foodDetail',
        query: {
          hashKey: hashKey,
        },
      });
    },
    /*-------------------------------------------------经络穴位-------------------------------------------------*/
    //左侧分类获取
    //左侧分类获取
    getMeridianNavListData: function () {
      var _this = this;
      _this.meridianDomain.Categorys(
        function (data) {
          _this.meridianNavList = data.data;
          _this.meridianNavList.forEach((item) => {
            if (item.childrens && item.childrens.length > 0) {
              item.childrens.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.childrens = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("经络穴位分类请求失败:", error);
        }
      );
    },
    //右侧经络穴位列表获取
    searchMeridian: function (cateId, isPage = false) {
      var _this = this;
      _this.rloading = _this.openLoading();
      if (!isPage) pageIndex = 1;
      if (cateId == undefined) cateId = 0;
      let params = `/${cateId}/${pageIndex}`;
      if (this.keyWord != "" && this.keyWord != undefined) {
        params = params + `/${this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.meridianDomain.Meridians(
        params,
        function (data) {
          $(".highlights-text").parent().removeClass("foucs");
          if (data.data.results) {
            data.data.results.forEach((item) => {
              if (item.acupoints && item.acupoints.length > 0) {
                item.acupoints.forEach((itemAcupoints) => {
                  if (itemAcupoints.acupointName && _this.keyWord) {
                    // 匹配关键字正则
                    let replaceReg = new RegExp(_this.keyWord, "g");
                    // 高亮替换v-html值
                    let replaceString = '<span class="highlights-text">' + _this.keyWord + "</span>";
                    // 开始替换
                    itemAcupoints.acupointName = itemAcupoints.acupointName.replace(replaceReg, replaceString);
                  }
                });
              }
            });
          }
          _this.meridianDetailList = data.data.results;
          _this.meridiantotal = data.data.dataTotal;
          categoryId = cateId;
          _this.rloading.close();
          _this.$nextTick(() => {
            $(".highlights-text").parent().addClass("foucs");
          });
        },
        function (error) {
          console.log("经络穴位列表请求失败:", error);
        }
      );
      if (!isPage) {
        this.currentPage = 1;
      }
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //分页点击事件
    handleCurrentChange: function (pageNum) {
      this.currentPage = pageNum;
      pageIndex = pageNum;
      this.searchMeridian(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    //跳转穴位详情
    //跳转穴位详情
    toAcupointDetail(hashKey) {
      toAcupoint(hashKey, this.searchId, this.keyword);
    },
    //跳转经络详情
    toMeridianDetail(hashKey) {
      toMeridian(hashKey, this.searchId, this.keyword);
    },
    /*-------------------------------------------------健康饮食-------------------------------------------------*/
    toHealthyDiet(hashKey) {
      toHealthyDetail(hashKey);
    },
    healthyhandleCurrentChange(pageNum) {
      this.healthycurrentPage = pageNum;
      pageIndex = pageNum;
      this.searchHealthyDiet(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    getHealthyDietnNavListData() {
      var _this = this;
      _this.herbalnavDomain.Categorys(
        function (data) {
          _this.healthyNavList = data.data;
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    searchHealthyDiet(cateId, isPage = false) {
      categoryId = cateId;
      var _this = this;
      _this.rloading = _this.openLoading();
      if (!isPage) pageIndex = 1;
      if (cateId == undefined) cateId = 0;
      let params = `/${cateId}/${pageIndex}`;
      if (_this.keyWord != "" && _this.keyWord != undefined) {
        params = params + `/${_this.keyWord}`;
      } else {
        params += "/%20";
      }
      _this.herbalnavDomain.HealthyDiets(
        params,
        function (data) {
          _this.healthyDetailList = data.data.results;
          _this.healthytotal = data.data.dataTotal;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("中药方剂请求失败:", error);
        }
      );
      if (!isPage) {
        this.currentPage = 1;
      }
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //地址栏参数关键词
    searchHeper(activeName) {
      let query = this.$router.history.current.query;
      let path = this.$router.history.current.path;
      //对象的拷贝
      let newQuery = JSON.parse(JSON.stringify(query));
      newQuery.keyWord = this.keyWord;
      newQuery.isClinical = this.isClinical;
      newQuery.activeName = activeName;
      this.$router.push({ path, query: newQuery });
      //this.$route.query.keyword = this.keyWord
    },
  },
};
</script>

<style scoped>
.content {
  background: #fcfcfc;
}

.search-box {
  padding: 50px 0;
}

.search {
  width: 720px;
  margin: 0 auto;
  padding-left: 15px;
  height: 54px;
  line-height: 52px;
  border: 1px solid #1b9183;
  border-radius: 27px;
  box-sizing: border-box;
  overflow: hidden;
  background: #fcfcfc;
}

.search input {
  padding: 0 5px;
}

.search .search-btn {
  width: 93px;
  height: 54px;
  background: #1b9183;
  vertical-align: top;
  color: #ffffff;
  font-size: 16px;
}

.search-tabBox {
  background: #ffffff;
}

.search-tab {
}

.content-box {
  width: 1440px;
  margin: 0 auto;
}

.content-left {
  float: left;
  width: 320px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

.zhongxi {
  margin-bottom: 30px;
}

.el-radio.is-bordered {
  border-radius: 2px;
  width: 156px;
  text-align: center;
}

.result-tit {
  padding: 15px 0 15px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.xi .result-tit span,
.xi .result-tit em {
  color: #00ab98;
  font-style: normal;
}

.zhong .result-tit span,
.zhong .result-tit em {
  color: #b76c12;
  font-style: normal;
}

.content-right {
  float: right;
  width: 1040px;
}

.sidenav {
}

.xi .sidenav > h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
  font-weight: bold;
}

.zhong .sidenav > h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #b76c12;
  font-weight: bold;
}

.result-list {
}

.resule-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border-bottom: 1px solid #e6e6e6;
}

.xi .resule-item:hover {
  border-bottom: 1px solid #00ab98;
}

.zhong .resule-item:hover {
  border-bottom: 1px solid #b76c12;
}

.resule-left {
  float: left;
  padding: 6px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
}

.resule-left.zy {
  background: #b76c12;
}

.resule-left.xy {
  background: #1fb7a6;
}

.cnname {
  max-width: 310px;
  margin-left: 10px;
  margin-right: 35px;
}

.cnname h3 {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}

.cnname p {
  font-size: 14px;
  color: #808080;
}

.jiantou {
  margin: 0 10px;
}

.jiantou p {
  font-size: 12px;
  color: #333;
}

.jiantou .jiantou-img {
  text-align: center;
  line-height: 30px;
}

.enname {
  width: 300px;
  font-size: 18px;
  color: #06a290;
  margin-left: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.symptom {
  width: 750px;
  margin: 15px 0 0 10px;
  color: #808080;
  font-size: 14px;
}

.symptom p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.resule-star {
  margin-right: 70px;
}

.resule-star i {
  font-size: 26px;
  color: #15aa9d;
}

.img-box {
  width: 800px;
  margin: 15px 0;
  position: relative;
  height: 150px;
  overflow: hidden;
}

.img-item {
  height: 80px;
}

/*    */
.drugName {
  margin-left: 10px;
  width: 600px;
}

.drugName h3 {
  font-size: 18px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.indication {
  width: 600px;
  margin: 15px 0 0 10px;
  color: #808080;
  font-size: 14px;
}

.indication p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.drug .resule-info {
  cursor: pointer;
}

.drug .resule-left {
  padding: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
}

.resule-star p span {
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.resule-star p span.add-cpt {
  color: #b72e0e;
}

.resule-star p span.add-col {
  color: #1fb7a6;
}

/*中药*/
.material-item {
  position: relative;
  margin: 0 23px 20px 23px;
  float: left;
  width: 250px;
  height: 240px;
  border-radius: 10px;
  border: 1px solid #f0e9e2;
  background: #faf7f3;
  overflow: hidden;
}

.material-item .material-img {
  width: 100%;
  height: 190px;
  overflow: hidden;
}

.material-item .material-img .img-box {
  width: 100%;
  margin: 0;
  display: block;
  text-align: center;
  line-height: 190px;
  height: 190px;
  overflow: hidden;
}

.material-item .material-img .img-box img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.material-item .material-tit {
  width: 100%;
  height: 50px;
  background: #ffffff;
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
}

.material-name h3 {
  line-height: 48px;
  cursor: pointer;
}

.material-name:hover h3 i {
  color: #b76c12;
}

.material-name h3 i {
  display: inline-block;
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  vertical-align: top;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-name h3 span {
  display: inline-block;
  margin-left: 12px;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  vertical-align: top;
  max-width: 114px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-hover {
  position: absolute;
  width: 250px;
  height: 40px;
  background: rgba(194, 134, 62, 0.6);
  top: -40px;
  transition: all linear 0.2s;
  cursor: pointer;
}

.result-hover .add-pw {
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: left;
  color: #ffffff;
}

.result-hover .add-pw:hover {
  color: #b76c12;
}

.collect-btn i {
  padding-right: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
}

.collect-btn i:hover {
  color: #b76c12;
}

.material-item:hover .result-hover {
  top: 0px;
  transition: all linear 0.2s;
}

/*食疗药膳&中药方剂*/
.food-list {
}

.food-list .food-item {
  position: relative;
  padding: 15px 20px;
  margin-bottom: 10px;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
}

.food-list .food-item:hover {
  border-bottom: 1px solid #b76c12;
  background: rgba(31, 183, 166, 0.01);
}

.food-list .food-item:hover h3 {
  color: #b76c12;
  cursor: pointer;
}

.food-tit {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
}

.meridian-tit {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
}

.meridian-tit:hover {
  color: #b76c12;
  cursor: pointer;
}

.food-tit span {
  font-size: 12px !important;
  color: #fff;
  background-color: #b76c12;
  border-radius: 10px;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  padding: 0 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.food-item p {
  margin-top: 10px;
  color: #666666;
}

.food-item p em {
  font-style: normal;
}

.sc-btn i {
  position: absolute;
  right: 43px;
  top: 15px;
  font-size: 26px;
  color: #b76c12;
  height: 36px;
  line-height: 36px;
  display: block;
  padding: 0 20px;
}

.el-icon-star-off {
  cursor: pointer;
}

/*page*/
.page {
  text-align: center;
  padding: 100px 0 30px;
}
</style>
<style>
.foucs {
  background: #e6a23c !important;
  border-color: #e6a23c !important;
  color: #ffffff !important;
}

#searchList .head-right .search {
  display: none !important;
}

#com-header .header {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  position: relative;
}

#searchList .el-select {
  width: 110px;
}

#searchList .el-input__inner {
  border: none;
  padding: 0 5px;
  font-size: 16px;
  background: #fcfcfc;
}

#searchList .el-tabs__header {
  background: #fcfcfc;
}

#searchList .el-tabs__nav-scroll {
  width: 600px;
  margin: 0 auto;
  overflow: hidden;
}

#searchList .el-tabs__nav-wrap::after {
  height: 0;
}

#searchList .el-tabs__item {
  font-size: 16px;
}

#searchList .el-tabs__content {
  width: 1440px;
  margin: 0 auto;
  min-height: 400px;
}

#searchList .el-radio {
  margin-right: 0;
}

#searchList .el-radio.is-bordered {
  width: 155px;
}
</style>
<style scoped>
.sidenav /deep/ .el-submenu__title > span {
  font-size: 16px;
  font-weight: 700;
}

.sidenav /deep/ .el-submenu__title i {
  color: #ffffff;
}

.xi /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #1fb7a6;
  color: #ffffff;
  margin-top: 7px;
}

.zhong /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #b76c12;
  color: #ffffff;
  margin-top: 7px;
}

.xi .subtitle /deep/ .el-submenu__title {
  padding-left: 30px !important;
  background: #fff;
  color: #333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.xi .subtitle /deep/ .el-submenu__title:hover {
  background: rgba(31, 183, 166, 0.2);
}

.xi .subtitle /deep/ .el-submenu__title i {
  color: #000000;
}

.xi .el-menu-item.is-active,
.xi .el-menu-item:hover {
  color: #01c2ac;
  background: rgba(31, 183, 166, 0.2);
}

.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
  color: #b76c12;
  background: rgba(183, 108, 18, 0.2);
}

.el-image__inner {
  height: 150px;
  width: auto;
}

/*----------------*/
.el-menu {
  border-right: none;
}

.el-submenu__title i {
  color: #ffffff;
}

.el-menu-item,
.el-submenu__title {
  height: 48px;
  line-height: 48px;
}

.subtitle {
  border: 1px solid #f2f2f2;
}

.subtitle .el-submenu__title i {
  color: #333333;
}

.subtitle .el-submenu__title {
  padding-left: 30px !important;
  background: #ffffff;
  color: #333333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.el-submenu .el-menu-item {
  height: 36px;
  line-height: 36px;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ffffff;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #1fb7a6;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  border-radius: 50%;
}

.el-pagination {
  font-weight: 500;
}

.el-submenu__title * {
  margin-left: 10px;
}

.el-submenu__title > span {
  font-size: 16px;
  font-weight: 700;
}

.zhong .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #b76c12;
  color: #fff;
}

/*.food .el-pagination.is-background .el-pager li.number:hover{
        color: #b76c12;
    }*/
.zhong .el-pagination.is-background .el-pager li:hover {
  color: #b76c12;
}

/**.头部按钮样式 */
.xi /deep/ .el-radio.is-bordered.is-checked {
  border-color: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #01c2ac;
  background: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #01c2ac;
}

.zhong /deep/ .el-radio.is-bordered.is-checked {
  border-color: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #b76c12;
  background: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #b76c12;
}

.zhong /deep/ .el-radio__inner:hover {
  border-color: #b76c12;
}
</style>
